/* All fonts in ../assets/fonts */
@font-face {
  font-family: "Brandon-bold";
  src: local("Brandon-bold"),
    url(../assets/fonts/Brandon-bold.otf) format("opentype");
}
@font-face {
  font-family: "Brandon-med";
  src: local("Brandon-med"),
    url(../assets/fonts/Brandon-med.otf) format("opentype");
}
@font-face {
  font-family: "Brandon";
  src: local("Brandon"), url(../assets/fonts/Brandon.otf) format("opentype");
}
@font-face {
  font-family: "Helvetica-bold";
  src: local("Helvetica-bold"),
    url(../assets/fonts/Helvetica-bold.ttf) format("truetype");
}
@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url(../assets/fonts/Helvetica.ttf) format("truetype");
}
@font-face {
  font-family: "Rawson";
  src: local("Rawson"), url(../assets/fonts/Rawson.woff2) format("woff2");
}

/*Heading*/
/*Regular*/
@font-face {
  font-family: "Heading1";
  src: local("Brandon"), url(../assets/fonts/Brandon.otf) format("opentype");
}
@font-face {
  font-family: "Heading2";
  src: local("Brandon"), url(../assets/fonts/Brandon.otf) format("opentype");
}
@font-face {
  font-family: "Heading3";
  src: local("Brandon"), url(../assets/fonts/Brandon.otf) format("opentype");
}
@font-face {
  font-family: "Heading4";
  src: local("Brandon"), url(../assets/fonts/Brandon.otf) format("opentype");
}
@font-face {
  font-family: "Heading5";
  src: local("Brandon"), url(../assets/fonts/Brandon.otf) format("opentype");
}
/*Bold*/
@font-face {
  font-family: "Heading1_bold";
  src: local("Brandon-bold"),
    url(../assets/fonts/Brandon-bold.otf) format("opentype");
}
@font-face {
  font-family: "Heading2_bold";
  src: local("Brandon-bold"),
    url(../assets/fonts/Brandon-bold.otf) format("opentype");
}
@font-face {
  font-family: "Heading3_bold";
  src: local("Brandon-bold"),
    url(../assets/fonts/Brandon-bold.otf) format("opentype");
}
@font-face {
  font-family: "Heading4_bold";
  src: local("Brandon-bold"),
    url(../assets/fonts/Brandon-bold.otf) format("opentype");
}
@font-face {
  font-family: "Heading5_bold";
  src: local("Brandon-bold"),
    url(../assets/fonts/Brandon-bold.otf) format("opentype");
}

/*Paragraph*/
/*Regular*/
@font-face {
  font-family: "Paragraph0";
  src: local("Brandon"), url(../assets/fonts/Brandon.otf) format("opentype");
}
@font-face {
  font-family: "Paragraph1";
  src: local("Brandon"), url(../assets/fonts/Brandon.otf) format("opentype");
}
@font-face {
  font-family: "Paragraph2";
  src: local("Brandon"), url(../assets/fonts/Brandon.otf) format("opentype");
}
@font-face {
  font-family: "Paragraph3";
  src: local("Brandon"), url(../assets/fonts/Brandon.otf) format("opentype");
}
/*Bold*/
@font-face {
  font-family: "Paragraph1_bold";
  src: local("Brandon-bold"),
    url(../assets/fonts/Brandon-bold.otf) format("opentype");
}
@font-face {
  font-family: "Paragraph2_bold";
  src: local("Brandon-bold"),
    url(../assets/fonts/Brandon-bold.otf) format("opentype");
}
@font-face {
  font-family: "Paragraph3_bold";
  src: local("Brandon-bold"),
    url(../assets/fonts/Brandon-bold.otf) format("opentype");
}

/*Label*/
/*Regular*/
@font-face {
  font-family: "Label";
  src: local("Helvetica"), url(../assets/fonts/Helvetica.ttf) format("truetype");
}
/*Bold*/
@font-face {
  font-family: "Label_bold";
  src: local("Helvetica-bold"),
    url(../assets/fonts/Helvetica-bold.ttf) format("truetype");
}
