h1 {
  font-family: Heading1;
  font-style: normal;
  font-weight: 450;
  font-size: 48px;
  line-height: 69px;
  text-transform: uppercase;
}
h2 {
  font-family: Heading2;
  font-style: normal;
  font-weight: 420;
  font-size: 20px;
  line-height: 29px;
}
h3 {
  font-family: Heading3;
  font-style: normal;
  font-weight: 420;
  font-size: 16px;
  line-height: 23px;
}
h4 {
  font-family: Heading4;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.14em;
}
h5 {
  font-family: Heading5;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
}
.p0 {
  font-family: Paragraph0;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
}
.p1 {
  font-family: Paragraph1;
  font-style: normal;
  font-weight: 390;
  font-size: 14px;
  line-height: 20px;
}
.p2 {
  font-family: Paragraph2;
  font-style: normal;
  font-weight: 420;
  font-size: 18px;
  line-height: 30px;
}
.p3 {
  font-family: Paragraph3;
  font-style: normal;
  font-weight: 420;
  font-size: 18px;
  line-height: 40px;
}
.label {
  font-family: Label;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}
.h1b {
  font-family: Heading1_bold;
}
.h2b {
  font-family: Heading2_bold;
}
.h3b {
  font-family: Heading3_bold;
}
.h4b {
  font-family: Heading4_bold;
}
.h5b {
  font-family: Heading5_bold;
}
.p1b {
  font-family: Paragraph1_bold;
}
.h2b {
  font-family: Paragraph2_bold;
}
.h3b {
  font-family: Paragraph3_bold;
}
.labelb {
  font-family: Label_bold;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
}
