@font-face {
  font-family: Brandon;
  src: url("assets/fonts/Brandon.otf");
}

@font-face {
  font-family: Brandon-bold;
  src: url("assets/fonts/Brandon-bold.otf");
}

@font-face {
  font-family: Brandon-med;
  src: url("assets/fonts/Brandon-med.otf");
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-button {
  height: 1px;
}

::-webkit-scrollbar-thumb {
  background-color: #f7b80b;
  border: 1px solid grey;
  border-radius: 24px;
}

/*@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');*/
.App {
  text-align: center;
  color: #0d2734;
  /* max-height: 100vh; */
  /* overflow: hidden; */
}

.main-container {
  margin-bottom: 60px !important;
}

@media (max-width: 1600px) {
  .main-container {
    margin-bottom: 90px !important;
  }
}

.pageFooter-wrapper {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.85);
  left: 0;
  padding: 0 2rem;
  z-index: 1;
  backdrop-filter: blur(1px);
}

.pageFooter {
  max-width: 1566px;
  width: 100%;
  margin: 0 auto;
}

.Yellow-footer {
  max-width: 1566px;
  transform: translateX(-50%);
  z-index: -1;
  position: fixed;
  bottom: 0;
  left: 0;
  border-right: 100vw solid transparent;
  border-bottom: 5vmin solid #f7b80b;
}

@media (max-width: 1600px) {
  .pageFooter,
  .Yellow-footer {
    max-width: 1182px;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-res-too-low {
  text-align: center;
  color: #0d2734;
  flex: auto;
}

.Home {
  text-align: center;
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  align-self: center;
}

.Home-logo {
  height: 30vmin;
  padding-top: 40vmin;
  pointer-events: none;
}

.Home-help {
  height: 50vmin;
  pointer-events: none;
  opacity: 0%;
}

.Home-center {
  display: flex;
  flex-direction: column;
}

.Home-content {
  width: 45vw;
  height: 45vh;
  justify-content: center;
  align-items: center;
}

.Home-right {
  position: absolute;
  right: 20%;
  width: 8vw;
}

.Text1 {
  opacity: 0%;
  margin-top: -5vh;
}

.Text3 {
  opacity: 0%;
}

.Text4 {
  opacity: 0%;
}

.Text2 {
  opacity: 0%;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.Home-button {
  opacity: 0%;
  display: flex;
  justify-content: center;
}

@media (prefers-reduced-motion: no-preference) {
  .Home-logo {
    animation: Home-logo-move 1s 0.5s 1 forwards;
  }

  .Home-help {
    animation: Fade-in 0.5s 0.5s 1 forwards;
  }

  .Text1 {
    animation: Fade-in 0.5s 1.5s 1 forwards;
  }

  .Text2 {
    animation: Fade-in 0.5s 2s 1 forwards;
  }

  .Text3 {
    animation: Fade-in 0.5s 2.5s 1 forwards;
  }

  .Text4 {
    animation: Fade-in 0.5s 3s 1 forwards;
  }

  .Home-button {
    animation: Fade-in 0.5s 3s 1 forwards;
  }
}

@keyframes Home-logo-move {
  0% {
    padding-top: 40vmin;
  }
  100% {
    padding-top: 0vmin;
  }
}

@keyframes Fade-in {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

div.DateInput {
  width: 90px !important;
}
